window.addEventListener('load', () => {
    openDesktopSubMenu();
    openMobileSubMenu();
    setTitleElementSize();
    burgerAction();
    popup();
    video();
});

window.addEventListener('resize', () => {
});

// window.addEventListener('scroll', () => {
//     hiddenDescktopMenuOnScroll();
// });
  
const body = document.querySelector('body');

function setTitleElementSize() {
    const titleWrappersLines = body.querySelectorAll('.title-wrapper .lines');

    if (titleWrappersLines[0]) {
        titleWrappersLines.forEach(line => {
            line.style.width = line.clientHeight+"px";
        });            
    }
}

function burgerAction() {
    const burger = body.querySelector('.header .js-burger');
    const menu = body.querySelectorAll('.header .nav-main');

    // document.addEventListener('click', function(e) {
    //     const checkmenu = e.composedPath().includes(menu);
    //     const checkburger = e.composedPath().includes(burger);

    //     if ( !checkmenu && !checkburger && menu[0].classList.contains('show') ) {
    //         menu[0].classList.remove('show');
    //         // body.classList.remove('no-scroll');
    //     }
    // });

    burger.addEventListener('click', ()=>{
        menu.forEach(element => {
            element.classList.toggle('show');            
        });
        // body.classList.toggle('no-scroll');
    });
}

function openDesktopSubMenu() {
    const menu = body.querySelector('.nav-main-desktop');
    const allLinks = menu.querySelectorAll('.main-link');
    const links = menu.querySelectorAll('.main-link.has-child');
    const sub = menu.querySelectorAll('.menu-sub-wrapper');

    sub.forEach((element) => {
        element.addEventListener('mouseleave', ()=> {
            element.classList.remove('active');
        });
    });

    links.forEach((link, id) => {
        link.addEventListener('click', (e)=> {
            e.preventDefault();
        });
        link.addEventListener('mouseenter', (e)=> {
            e.preventDefault();

            sub.forEach(element => {
                element.classList.remove('active');
            });
            links.forEach(element => {
                element.classList.remove('active');
            });
            sub[id].classList.add('active');
            link.classList.add('active');
        });
    });

    allLinks.forEach(link => {
        link.addEventListener('mouseenter', ()=> {
            if (!link.classList.contains('has-child')) {
                sub.forEach(element => {
                    element.classList.remove('active');
                });
                links.forEach(element => {
                    element.classList.remove('active');
                });
            }
        });
    });
}

// function hiddenDescktopMenuOnScroll() {
//     const menu = body.querySelector('.nav-main-desktop');
//     const header = body.querySelector('.header .header-wrapper');

//     if (window.scrollY > 300) {
//         menu.classList.remove('show');
//         header.classList.add('min');
//       } else {
//         menu.classList.add('show');
//         header.classList.remove('min');
//     }
// }

function openMobileSubMenu() {
    const menus = body.querySelectorAll('.nav-main-mobile .menu-item-has-children');

    menus.forEach(menu => {
        menu.addEventListener('click', (e)=>{
            e.preventDefault();

            menus.forEach(element => {
                element.classList.remove('open');
            });
            menu.classList.add('open');
        });
    });
}

function popup() {
    const buttons = body.querySelectorAll('.js-open-form');

    buttons.forEach(button => {
        button.addEventListener('click', (e)=> {
            e.preventDefault();
            const id = button.dataset.id;
            const popup = body.querySelector('.js-popup'+id);
            const close = popup.querySelector('.close-popup');
            popup.classList.add('show');
            body.classList.add('no-scroll');

            close.addEventListener('click', ()=>{
                popup.classList.remove('show');
                body.classList.remove('no-scroll');
            })

            popup.addEventListener('click', (e)=> {
                const target = e.target;

                if (target === popup) {
                    popup.classList.remove('show');
                    body.classList.remove('no-scroll');                        
                }
            })
        })
    });
}

function video() {
    const videos = body.querySelectorAll('.video.file');

    videos.forEach(element => {
        if (element) {
            const video = element.querySelector('video');
            const button = element.querySelector('.button-play');
            video.currentTime = 1;

            element.addEventListener('click', (e)=> {
                const target = e.target;
                
                if (target != button) {
                    element.classList.remove('play');
                    video.pause();
                }
            });

            button.addEventListener('click', ()=> {
                element.classList.add('play');
                video.play();
            });
        }
    });
}